import React, { useState } from "react";
import I18n from "../services/LanguageService";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonItem,
  IonButtons,
  IonText,
  IonList,
  IonListHeader,
} from "@ionic/react";

// Components
import DynamicRedButtonLogo from "../components/atom/top-bar/DynamicRedButtonLogo";

// Services
import { returnSupportedLanguages } from "../services/LanguageService";

// Interfaces
import SettingsPageInterface from "../interfaces/settings/SettingsPageInterface";

const Settings: React.FC<SettingsPageInterface> = ({
  changeLanguageHandler,
  currentLanguage,
  sportCode,
  translationGroup,
}) => {
  const [availableLanguages] = useState(
    returnSupportedLanguages(translationGroup)
  );
  // This saves the language chosen in the select
  const [currentLanguageState, setCurrentLanguageState] = useState(
    currentLanguage
  );

  const updateCurrentLanguage = () => {
    changeLanguageHandler(currentLanguageState);
  };

  const setChosenLanguage = (input: string) => {
    setCurrentLanguageState(input);
  };

  const languageOptions = () => {
    if (availableLanguages) {
      return Object.values(availableLanguages).map((language: any) => (
        <IonSelectOption
          className="c-select__option"
          data-test-id="language-option"
          key={language["code"]}
          value={language["code"]}
        >
          {language["name"]}
        </IonSelectOption>
      ));
    }
  };

  return (
    <IonPage>
      <IonHeader className="c-header">
        <IonToolbar className="c-toolbar">
          <IonItem slot="start" className="c-redbutton-logo" lines="none">
            <DynamicRedButtonLogo sportCode={sportCode} />
          </IonItem>
          <h1 className="c-header__title">{I18n.t("tab_settings")}</h1>
          <IonButtons className="c-header__cta" slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-content ion-content--has-bg">
        <div className="ion-content__bg-image">
          <div className="c-settings">
            <div className="c-settings__inner">
              <IonList>
                <IonItem className="c-ion-item">
                  <IonListHeader className="c-ion-list-header">
                    {I18n.t("settings_language")}
                  </IonListHeader>
                </IonItem>
                <IonItem className="c-ion-item">
                  <IonLabel className="o-label">
                    <span className="icon-dropdown"></span>
                    <IonSelect
                      className="o-select"
                      cancelText={I18n.t("popup_cancel")}
                      okText={I18n.t("popup_ok")}
                      value={currentLanguageState}
                      onIonChange={(e) => setChosenLanguage(e.detail.value)}
                      interface="popover"
                      data-test-id="language-select"
                    >
                      {languageOptions()}
                    </IonSelect>
                  </IonLabel>
                </IonItem>
              </IonList>
            </div>
          </div>

          <IonText className="c-form">
            <IonButton
              data-test-id="language-submit"
              className="btn btn--solid"
              onClick={() => updateCurrentLanguage()}
            >
              <span>{I18n.t("settings_save_changes")}</span>
              <span className="icon-check"></span>
            </IonButton>
          </IonText>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
