import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonText,
  IonToolbar,
} from "@ionic/react";
import I18n from "../services/LanguageService";

// Videos
import {
  getPlayerVideoUrl,
  getInterviewVideoUrl,
} from "../services/VideoService";

// Components
import DynamicRedButtonLogo from "../components/atom/top-bar/DynamicRedButtonLogo";

// Constants
import EUA_LEAGUES from "../constants/EuaLeagues";

// Interfaces
import VideoPlayerInterface from "../interfaces/content/VideoPlayerInterface";
import RedButtonInterface from "../interfaces/red-button/RedButtonInterface";

const RedButton: React.FC<RedButtonInterface> = ({
  sportCode,
  unionCode,
  preferredLanguage,
}) => {
  const [playerVideoSettings] = useState<VideoPlayerInterface>({
    url: getPlayerVideoUrl(preferredLanguage),
    playing: false,
  });
  const [interviewVideoSettings] = useState<VideoPlayerInterface>({
    url: getInterviewVideoUrl(preferredLanguage),
    playing: false,
  });
  const [content, setContent] = useState<string[]>([]);
  const [tailContent, setTailContent] = useState<string[]>([]);

  useEffect(() => {
    let text: string[] = [];

    if (unionCode === EUA_LEAGUES.PROVALE) {
      text = I18n.t("redbutton_description_fr")
        ? I18n.t("redbutton_description_fr").split("\r\n\r\n")
        : [];
      setTailContent(text.splice(4));
    }

    if (unionCode === EUA_LEAGUES.AJFSF) {
      text = I18n.t("redbutton_description_f")
        ? I18n.t("redbutton_description_f").split("\r\n\r\n")
        : [];
    }

    if (text.length === 0) {
      text = I18n.t("redbutton_description").split("\r\n\r\n");
    }

    setContent(text);

  }, [unionCode]);

  const tailendContent = () => {
    if (tailContent.length > 0) {
      return tailContent.map((content: string, index: number) => {
        return <p key={`extra-content-${index}`}>{content}</p>;
      });
    }
  };

  return (
    <IonPage>
      <IonHeader className="c-header">
        <IonToolbar className="c-toolbar">
          <IonItem slot="start" className="c-redbutton-logo" lines="none">
            <DynamicRedButtonLogo sportCode={sportCode} />
          </IonItem>
          <h1 className="c-header__title">{I18n.t("tab_home")}</h1>
          <IonButtons className="c-header__cta" slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-content">
        <IonItem className="c-banner c-banner--red-button">
          <div className="c-banner__content">
            <DynamicRedButtonLogo
              sportCode={sportCode}
              lightLogo={true}
              className="c-redbutton-logo c-redbutton-logo--medium"
            />
          </div>
        </IonItem>

        <IonText className="c-text">
          <h2>{I18n.t("tab_home")}</h2>
          <p>{content[0]}</p>
          <p>{content[1]}</p>
        </IonText>

        <IonText className="c-text">
          <ReactPlayer
            className="c-video"
            width="100%"
            height="100%"
            playing={interviewVideoSettings.playing}
            url={interviewVideoSettings.url}
            controls
          />
        </IonText>

        <IonText className="c-text">
          <p>{content[2]}</p>
          <p>{content[3]}</p>
        </IonText>

        {(unionCode === "eua-provale" || unionCode === "eua-afsjf") && (
          <IonText className="c-text">{tailendContent()}</IonText>
        )}

        <IonText className="c-text">
          <ReactPlayer
            className="c-video"
            width="100%"
            height="100%"
            playing={playerVideoSettings.playing}
            url={playerVideoSettings.url}
            controls
          />
        </IonText>
      </IonContent>
    </IonPage>
  );
};

export default RedButton;
