export const getCodeOfConductVideoUrl = () =>
  `${process.env.REACT_APP_VIDEO_BASE_URL}Code_of_Conduct_v5.mp4`;

export const getPlayerVideoUrl = (language: string) => {
  switch (language) {
    case "es":
      return `${process.env.REACT_APP_VIDEO_BASE_URL}PLAYERVIDEO_FIFPRO_ESP_FINAL.mp4`;
    case "fr":
      return `${process.env.REACT_APP_VIDEO_BASE_URL}PLAYERVIDEO_FIFPRO_FR_FINAL.mp4`;
    default:
      return `${process.env.REACT_APP_VIDEO_BASE_URL}PLAYERVIDEO_FIFPRO_ENG_FINAL.mp4`;
  }
};

export const getInterviewVideoUrl = (language: string) => {
  switch (language) {
    case "es":
      return `${process.env.REACT_APP_VIDEO_BASE_URL}Samir+Arab_RedButtunApp_SPANISH.mp4`;
    case "fr":
      return `${process.env.REACT_APP_VIDEO_BASE_URL}Samir+Arab_RedButtunApp_FRENCH.mp4`;
    default:
      return `${process.env.REACT_APP_VIDEO_BASE_URL}Samir+Arab_RedButtunApp_ENGLISH.mp4`;
  }
};
