import React from "react";
import { IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import I18n from "../../../services/LanguageService";

// In the future this needs to be generic
// See: https://jira.hosted-tools.com/browse/FIRB-707
const VVSCContent: React.FC = () => {
  return (
    <div>
      <IonText className="c-text">
        <h2>{I18n.t("code_of_conduct_topic_1_vvcs")}</h2>
      </IonText>
      <IonList lines="none" className="c-list c-list--numbered">
        <IonItem>
          <IonLabel className="c-list__label"> 
              <span className="c-list__number"></span>
              <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_1a_vvcs")}
              </span>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="c-list__label"> 
              <span className="c-list__number"></span>
              <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_1b_vvcs")}
              </span>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="c-list__label"> 
              <span className="c-list__number"></span>
              <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_1c_vvcs")}
              </span>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="c-list__label"> 
              <span className="c-list__number"></span>
              <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_1d_vvcs")}
              </span>
          </IonLabel>
        </IonItem>
      </IonList>
      <IonText className="c-text">
        <h2>{I18n.t("code_of_conduct_topic_2_vvcs")}</h2>
      </IonText>
      <IonList lines="none" className="c-list c-list--numbered">
        <IonItem>
          <IonLabel className="c-list__label"> 
              <span className="c-list__number"></span>
              <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_2a_vvcs")}
              </span>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="c-list__label"> 
              <span className="c-list__number"></span>
              <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_2b_vvcs")}
              </span>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel className="c-list__label"> 
              <span className="c-list__number"></span>
              <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_2c_vvcs")}
              </span>
          </IonLabel>
        </IonItem>
      </IonList>

      <IonText className="c-text">
        <h2>{I18n.t("code_of_conduct_topic_3_vvcs")}</h2>
      </IonText>
      <IonText className="c-text">
        <h3>{I18n.t("code_of_conduct_topic_3a_title_vvcs")}</h3>
        <p>{I18n.t("code_of_conduct_topic_3a_description_vvcs")}</p>
      </IonText>
      <IonText className="c-text">
        <h3>{I18n.t("code_of_conduct_topic_3b_title_vvcs")}</h3>
        <p>{I18n.t("code_of_conduct_topic_3b_description_vvcs")}</p>
      </IonText>
      <IonText className="c-text">
        <h3>{I18n.t("code_of_conduct_topic_3c_title_vvcs")}</h3>
        <p>{I18n.t("code_of_conduct_topic_3c_description_vvcs")}</p>
      </IonText>
    </div>
  );
};

export default VVSCContent;
