import axios from "../api/baseApi";
// import MockAdapter from "axios-mock-adapter";
import { AxiosResponse } from "axios";

// Interfaces
import PostAttachment from "../interfaces/attachments/PostAttachment";
import Attachment from "../interfaces/attachments/Attachment";

// const sleep = (value: number) =>
//   new Promise((resolve) => setTimeout(resolve, value));
//
// const mock = new MockAdapter(axios);
//
// mock.onPost('/upload').reply(async (config: AxiosRequestConfig) => {
//   const total = 1024; // mocked file size
//   for (const progress of [0, 0.2, 0.4, 0.6, 0.8, 1]) {
//     await sleep(500);
//     if (config.onUploadProgress) {
//       config.onUploadProgress({ progression: total * progress, total, file: config.data.get("file") });
//     }
//   }
//   return [200, { id: "fdsfds", deleteToken: "ffdsf" }];
// })

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const uploadFile = async (file: PostAttachment) => {
  try {
    if (!file) {
      return;
    }

    let formData = new FormData();

    formData.append("UnionCode", file.data.unionCode);
    formData.append("UploadedAt", file.data.uploadedAt);
    formData.append("UploadedAtTimeZone", file.data.uploadedAtTimeZone);
    formData.append("file", file.data.file, file.data.file.name);

    return await axios
      .post(`${BASE_URL}attachment`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: file.onUploadProgress,
      })
      .then((result: AxiosResponse<Attachment>) => {
        switch (result.status) {
          case 200:
            file.completedAttachment = result.data;
            return file.onUploadComplete({
              progression: 100,
              deleteToken: result.data.deleteToken,
              id: result.data.id,
              name: file.data.file.name,
            });
          default:
            throw new Error("Something went wrong while uploading, please try again later.");
        }
      })
      .catch((error) => {
        switch (error.statusCode) {
          case 400:
            throw new Error("Something went wrong while uploading, please try again later.");
          case 401:
            throw new Error("You are not authorized to add attachments.");
          case 403:
            throw new Error("Forbidden to add attachments.");
          default:
            throw new Error("Something went wrong, please try again later.");
        }
      });
  } catch (error) {
    throw new Error("Something went wrong, please try again later");
  }
};
