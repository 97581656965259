import React, { useState } from "react";
import ReactPlayer from "react-player";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonToolbar,
  IonItem,
} from "@ionic/react";
import I18n from "../services/LanguageService";

// Videos
import { getCodeOfConductVideoUrl } from "../services/VideoService";

// Interfaces
import VideoPlayerInterface from "../interfaces/content/VideoPlayerInterface";
import CodeOfConductInterface from "../interfaces/code-of-conduct/CodeOfConductInterface";

// Images
import LogoFifpro from "../assets/images/brand/FIFPRO_logo__lg.svg";

// Components
import DynamicRedButtonLogo from "../components/atom/top-bar/DynamicRedButtonLogo";
import EuaContent from "../components/organism/code-of-conduct/EuaContent";
import RedButtonContent from "../components/organism/code-of-conduct/RedButtonContent";
import VVSCContent from '../components/organism/code-of-conduct/VVCSContent';

const CodeOfConduct: React.FC<CodeOfConductInterface> = ({
  translationGroup,
  sportCode,
  unionCode
}) => {
  const [videoSettings] = useState<VideoPlayerInterface>({
    url: getCodeOfConductVideoUrl(),
    playing: false,
  });

  const showVVCS = unionCode === "vvcs";
  const hasTranslationGroup = !!translationGroup;
  const contentToRender = () => {

    // In the future this needs to be generic
    // See: https://jira.hosted-tools.com/browse/FIRB-707
    if(showVVCS) {
      return <VVSCContent />
    }

    return hasTranslationGroup ? <EuaContent /> : <RedButtonContent />;
  };

  return (
    <IonPage>
      <IonHeader className="c-header">
        <IonToolbar className="c-toolbar">
          <IonItem slot="start" className="c-redbutton-logo" lines="none">
            <DynamicRedButtonLogo sportCode={sportCode} />
          </IonItem>
          <h1 className="c-header__title">{I18n.t("tab_code_of_conduct")}</h1>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-content">
        <IonItem className="c-banner c-banner--fifpro">
          <div className="c-banner__content">
            <img src={LogoFifpro} alt="fifpro-logo" />
          </div>
        </IonItem>

        {!hasTranslationGroup && (
          <IonText className="c-text">
            <h2>{I18n.t(showVVCS ? "code_of_conduct_advice_header_vvcs" : "code_of_conduct_advice_header")}</h2>
            <p>{I18n.t(showVVCS ? "code_of_conduct_following_advices_vvcs" : "code_of_conduct_following_advices")}</p>
          </IonText>
        )}

        {contentToRender()}

        {!showVVCS && !hasTranslationGroup && (
          <IonText className="c-text">
            <ReactPlayer
              className="c-video"
              width="100%"
              height="100%"
              playing={videoSettings.playing}
              url={videoSettings.url}
              controls
            />
          </IonText>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CodeOfConduct;
