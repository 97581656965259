import localforage from "localforage";

// Interfaces
import OldTokenInterface from "../interfaces/tokens/OldTokenInterface";

export const isMigrationRequired = async (): Promise<OldTokenInterface | null> => {
  const hasMigratedBefore = localStorage.getItem("rb-migrated");

  if (!hasMigratedBefore) {
    return await localforage.getItem("credentials");
  } else {
    return null;
  }
};
