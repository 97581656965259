import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

// Constants
import ROUTES from "./constants/Routes";

// Interfaces
import LoginStateInterface from "./interfaces/login/LoginStateInterface";

// Services
import I18n, {
  setUpdatedLanguage,
  decideLocale,
  isLanguageSupported,
} from "./services/LanguageService";
import {
  readTokenFromStorage,
  writeTokenToStorage,
} from "./services/TokenService";

// Pages
import CodeOfConduct from "./pages/CodeOfConduct";
import InfoPage from "./pages/InfoPage";
import Login from "./pages/Login";
import RedButton from "./pages/RedButton";
import Report from "./pages/Report";
import ReportConfirmation from "./pages/ReportConfirmation";
import Settings from "./pages/Settings";

// Components
import ProtectedRoute from "./components/atom/ProtectedRoute";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";

/* Theme variables */
import "./assets/ionic/theme/variables.css";

/* FIFPRO specific CSS */
import "./assets/style/fifpro.scss";

const App: React.FC = () => {
  const [loginState, setLoginState] = useState<LoginStateInterface>({
    isLoggedIn: false,
  });
  const [chosenLanguage, setChosenLanguage] = useState(decideLocale());

  useEffect(() => {
    setLoginState(readTokenFromStorage());
  }, []);

  const updateLanguage = (chosenLanguage: string) => {
    setChosenLanguage(chosenLanguage);
    setUpdatedLanguage(chosenLanguage, true);
    setLoginState({
      ...loginState,
      isLoggedIn: true,
      userLanguage: chosenLanguage,
    });
  };

  const loginSuccess = (result: LoginStateInterface) => {
    const isLocalLangSupported = isLanguageSupported(navigator.language, result.translationGroup!);

    result.userLanguage = isLocalLangSupported
      ? isLocalLangSupported.code
      : result.preferredLanguage;
    updateLanguage(result.userLanguage!);
    writeTokenToStorage(result);
    setLoginState(result);
  };

  const textDirection = () => {
    // If language is Ar(abic) or He(brew), inverse text direction. This also flips layout!
    return chosenLanguage === "ar" || chosenLanguage === "he" ? "rtl" : "ltr";
  };

  // TODO: place this in Icomoon later
  const phoneIcon = () => {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        y="0"
        x="0"
        height="18px"
        width="18px"
        className="icon-tabbar"
      >
        <path d="M44,8H20c-2.2,0-4,1.8-4,4v40c0,2.2,1.8,4,4,4h24c2.2,0,4-1.8,4-4V12C48,9.8,46.2,8,44,8z M20,52V12h6v4h12v-4h6l0,40H20z" />
        <rect x="30" y="44" width="4" height="4" />
      </svg>
    );
  };

  return (
    <IonApp dir={textDirection()}>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route
              path={ROUTES.LOGIN}
              render={() => {
                return loginState.isLoggedIn ? (
                  <Redirect to={ROUTES.REPORT} />
                ) : (
                  <Login loginCallback={loginSuccess} />
                );
              }}
              exact={true}
            />
            <Route
                path={ROUTES.INFO}
                component={InfoPage}
                exact={true}
            />
            <ProtectedRoute
              isLoggedIn={loginState.isLoggedIn}
              path={ROUTES.REPORT}
              render={() => (
                <Report
                  sportCode={loginState.sportCode!}
                  unionCode={loginState.unionCode!}
                />
              )}
            />
            <ProtectedRoute
              isLoggedIn={loginState.isLoggedIn}
              path={ROUTES.REPORT_CONFIRMATION}
              render={() => <ReportConfirmation sportCode={loginState.sportCode!} />}
            />
            <ProtectedRoute
              isLoggedIn={loginState.isLoggedIn}
              path={ROUTES.RED_BUTTON}
              render={() => (
                <RedButton
                  sportCode={loginState.sportCode!}
                  unionCode={loginState.unionCode!}
                  preferredLanguage={loginState.preferredLanguage!}
                />
              )}
            />
            <ProtectedRoute
              isLoggedIn={loginState.isLoggedIn}
              path={ROUTES.CODE_OF_CONDUCT}
              render={() => (
                <CodeOfConduct
                  translationGroup={loginState.translationGroup!}
                  sportCode={loginState.sportCode!}
                  unionCode={loginState.unionCode!}
                />
              )}
            />
            <ProtectedRoute
              isLoggedIn={loginState.isLoggedIn}
              path={ROUTES.SETTINGS}
              render={() => (
                <Settings
                  changeLanguageHandler={updateLanguage}
                  currentLanguage={chosenLanguage}
                  sportCode={loginState.sportCode!}
                  translationGroup={loginState.translationGroup!}
                />
              )}
            />
            <Route
              path="/"
              render={() => <Redirect to={ROUTES.LOGIN} />}
              exact={true}
            />
            <Route
              path="/index.html"
              render={() => <Redirect to={ROUTES.LOGIN} />}
              exact={true}
            />
          </IonRouterOutlet>
          <IonTabBar
            className="c-tapbar"
            slot="bottom"
            hidden={!loginState.isLoggedIn}
          >
            <IonTabButton
              className="c-tapbar__button"
              tab="report"
              href={ROUTES.REPORT}
              disabled={!loginState.isLoggedIn}
              data-test-id="report-button"
            >
              <span className="icon-warning"></span>
              <IonLabel className="c-tapbar__label">
                {I18n.t("tab_report")}
              </IonLabel>
            </IonTabButton>
            <IonTabButton
              className="c-tapbar__button"
              tab="codeofconduct"
              href={ROUTES.CODE_OF_CONDUCT}
              disabled={!loginState.isLoggedIn}
              data-test-id="coc-button"
            >
              <span className="icon-info-bordered"></span>
              <IonLabel className="c-tapbar__label">
                {I18n.t("tab_code_of_conduct")}
              </IonLabel>
            </IonTabButton>
            <IonTabButton
              className="c-tapbar__button"
              tab="redbutton"
              href={ROUTES.RED_BUTTON}
              disabled={!loginState.isLoggedIn}
              data-test-id="redbutton-button"
            >
              {phoneIcon()}
              <IonLabel className="c-tapbar__label">
                {I18n.t("tab_home")}
              </IonLabel>
            </IonTabButton>
            <IonTabButton
              className="c-tapbar__button"
              tab="settings"
              href={ROUTES.SETTINGS}
              disabled={!loginState.isLoggedIn}
              data-test-id="settings-button"
            >
              <span className="icon-settings"></span>
              <IonLabel className="c-tapbar__label">
                {I18n.t("tab_settings")}
              </IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
