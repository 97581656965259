import axios, { AxiosRequestConfig } from "axios";
import { readTokenFromStorage } from "../services/TokenService";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const tokenData = readTokenFromStorage();

  if (tokenData && tokenData.token) {
    config.headers.Authorization = tokenData.token;
  }

  return config;
});

export default axios;
