import React from "react";
import { useHistory } from "react-router";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonToolbar,
  IonButton,
} from "@ionic/react";
import I18n from "../services/LanguageService";

// Constants
import ROUTES from "../constants/Routes";

const InfoPage: React.FC = () => {
  const history = useHistory();

  return (
    <IonPage>
      <IonHeader className="c-header">
        <IonToolbar className="c-toolbar">
          <IonItem slot="start" className="c-header__logo" lines="none">
            <img
              src="assets/logos/redbutton/dark/FOO.png"
              alt="red-button-logo"
            />
          </IonItem>
          {/*TODO: make a proper language key for this in a later version*/}
          <h1 className="c-header__title">Info</h1>
          <IonButtons className="c-header__cta" slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-content ion-content--has-bg">
        <h1 className="c-header__title" style={{ margin: "16px 16px 0 16px" }}>
          {I18n.t("getting_started_title")}
        </h1>
        <IonList lines="none" className="c-list c-list--numbered">
          <IonItem>
            <IonLabel className="c-list__label">
              <span className="c-list__number">
                {I18n.t("code_of_conduct_bullet_1")}
              </span>
              <span
                className="c-list__label-txt"
                style={{ display: "block" }}
                dangerouslySetInnerHTML={{
                  __html: I18n.t("getting_started_text_1_1"),
                }}
              ></span>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="c-list__label">
              <span className="c-list__number">
                {I18n.t("code_of_conduct_bullet_2")}
              </span>
              <span
                className="c-list__label-txt"
                style={{ display: "block" }}
                dangerouslySetInnerHTML={{
                  __html: I18n.t("getting_started_text_1_2"),
                }}
              ></span>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="c-list__label">
              <span className="c-list__number">
                {I18n.t("code_of_conduct_bullet_3")}
              </span>
              <span
                className="c-list__label-txt"
                style={{ display: "block" }}
                dangerouslySetInnerHTML={{
                  __html: I18n.t("getting_started_text_2"),
                }}
              ></span>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="c-list__label">
              <span className="c-list__number">
                {I18n.t("code_of_conduct_bullet_4")}
              </span>
              <span
                className="c-list__label-txt"
                style={{ display: "block" }}
                dangerouslySetInnerHTML={{
                  __html: I18n.t("getting_started_text_3"),
                }}
              ></span>
            </IonLabel>
          </IonItem>
        </IonList>
        <IonButton
          className="btn btn--solid"
          onClick={() => history.push(ROUTES.LOGIN)}
        >
          Login
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default InfoPage;
