import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
  IonPage,
  IonInput,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonHeader,
} from "@ionic/react";

// Services
import { validateToken, migrateToken } from "../services/TokenService";
import { isMigrationRequired } from "../services/LocalForageService";
import I18n from "../services/LanguageService";

// Components
import { showToast } from "../components/atom/Toast";

// Constants
import ROUTES from "../constants/Routes";

// Interfaces
import LoginPageInterface from "../interfaces/login/LoginPageInterface";
import OldTokenInterface from "../interfaces/tokens/OldTokenInterface";

// Images
import redButtonLogoWhite from "../assets/images/brand/red-button-logo-white-txt.svg";
import LoginStateInterface from "../interfaces/login/LoginStateInterface";

const Login: React.FC<LoginPageInterface> = ({ loginCallback }) => {
  const [code, setCode] = useState<string>("");
  const history = useHistory();

  useEffect(() => {
    const migrationCheck = isMigrationRequired();
    if (migrationCheck) {
      migrationCheck.then((result: OldTokenInterface | null) => {
        if (result) {
          migrateToken(result).then(
            (migrateResult: LoginStateInterface | string) => {
              if (typeof migrateResult === "object") {
                localStorage.setItem("rb-migrated", JSON.stringify(true));
                migrateResult.isLoggedIn = true;
                loginCallback(migrateResult);
              }
            }
          );
        }
      });
    }
  }, [loginCallback]);

  const formSubmit = async (submitEvent?: any) => {
    if (submitEvent) {
      submitEvent.preventDefault();
    }

    try {
      const result = await validateToken(code);

      if (result && typeof result === "object") {
        result.isLoggedIn = true;
        loginCallback(result);
      } else {
        await showToast(I18n.t("login_invalid"), "long");
      }
    } catch (error) {
      await showToast(I18n.t("login_invalid"), "long");
    }
  };

  return (
    <IonPage>
      <IonHeader className="c-header">
        <IonToolbar className="c-toolbar">
          <IonItem slot="start" className="c-redbutton-logo" lines="none">
            <img
              src="assets/logos/redbutton/dark/FOO.png"
              alt="red-button-logo"
            />
          </IonItem>
          <h1 className="c-header__title">{I18n.t("login_title")}</h1>
          <IonButtons className="c-header__cta" slot="end">
            <IonButton onClick={() => history.push(ROUTES.INFO)}>
              <span className="icon-info-bordered"></span>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <main className="c-login-container">
        <div className="c-login">
          <div className="c-redbutton-logo c-redbutton-logo--large">
            <img src={redButtonLogoWhite} alt="red-button-logo" />
          </div>
          <h2>{I18n.t("getting_started_text_3")}</h2>
          <form onSubmit={formSubmit}>
            <IonInput
              data-test-id="login-field"
              onIonChange={(event) => setCode(event.detail.value!)}
              className="input input--light"
              placeholder={I18n.t("login_code_placeholder")}
            />
            <IonButton
              data-test-id="login-button"
              fill={"solid"}
              expand={"full"}
              onClick={formSubmit}
              className="btn btn--solid"
            >
              <span>{I18n.t("login_button")}</span>
              <span className="icon-next"></span>
            </IonButton>
          </form>

          <p>
            Your privacy is respected and we ensure that the personal
            information you provide to us is treated confidentially. Read our{" "}
            <a href="https://fifpro.org/media/i04b0iez/rb-privacy.pdf" target="_blank" rel="noopener noreferrer">
              privacy policy
            </a>{" "}
            for more information.
          </p>
        </div>
      </main>
    </IonPage>
  );
};

export default Login;
