// @ts-ignore
import I18n from "i18n-js";
import { writeTokenToStorage, readTokenFromStorage } from "./TokenService";

// Language sets
import ar from "../i18n/locales/ar.json"; // Arabic
import bg from "../i18n/locales/bg.json"; // Bulgarian
import bs from "../i18n/locales/bs.json"; // Bosnian
import cs from "../i18n/locales/cs.json"; // Czech
import cnr from "../i18n/locales/cnr.json"; // Montenegrin
import da from "../i18n/locales/da.json"; // Danish
import de from "../i18n/locales/de.json"; // German
import el from "../i18n/locales/el.json"; // Greek
import en from "../i18n/locales/en.json"; // English
import es from "../i18n/locales/es.json"; // Spanish
import fi from "../i18n/locales/fi.json"; // Finnish
import fr from "../i18n/locales/fr.json"; // French
import he from "../i18n/locales/he.json"; // Hebrew
import hr from "../i18n/locales/hr.json"; // Hungarian
import hu from "../i18n/locales/hu.json"; // Hungarian
import id from "../i18n/locales/id.json"; // Indonesian
import is from "../i18n/locales/is.json"; // Icelandic
import it from "../i18n/locales/it.json"; // Italian
import ja from "../i18n/locales/ja.json"; // Japanese
import ko from "../i18n/locales/ko.json"; // Korean
import ky from "../i18n/locales/ky.json"; // Kyrgyz
import mk from "../i18n/locales/mk.json"; // Macedonian
import ml from "../i18n/locales/ml.json"; // Malay
import mt from "../i18n/locales/mt.json"; // Maltese
import nd from "../i18n/locales/nd.json"; // Zimbabwe-Ndebele
import nl from "../i18n/locales/nl.json"; // Dutch / Flemish
import no from "../i18n/locales/no.json"; // Norwegian
import pl from "../i18n/locales/pl.json"; // Polish
import pt from "../i18n/locales/pt.json"; // Portugese
import ro from "../i18n/locales/ro.json"; // Romanian
import ru from "../i18n/locales/ru.json"; // Russian
import sk from "../i18n/locales/sk.json"; // Slovakian
import sl from "../i18n/locales/sl.json"; // Slovenian
import sn from "../i18n/locales/sn.json"; // Zimbabwe-Shona
import sr from "../i18n/locales/sr.json"; // Serbian
import sv from "../i18n/locales/sv.json"; // Swedish
import th from "../i18n/locales/th.json"; // Thai
import tk from "../i18n/locales/tk.json"; // Turkish
import uk from "../i18n/locales/uk.json"; // Ukranian
import uz from "../i18n/locales/uz.json"; // Uzbek

const supportedLanguages = {
  all: [
    { code: "id", name: "bahasa Indonesia" },
    { code: "bs", name: "Bosanski" },
    { code: "cs", name: "čeština" },
    { code: "da", name: "Dansk" },
    { code: "de", name: "Deutsch" },
    { code: "en", name: "English" },
    { code: "es", name: "Español" },
    { code: "fr", name: "Français" },
    { code: "hr", name: "Hrvatski" },
    { code: "is", name: "íslensku" },
    { code: "it", name: "Italiano" },
    { code: "hu", name: "Magyar" },
    { code: "ml", name: "Malay" },
    { code: "mt", name: "Malti" },
    { code: "nl", name: "Nederlands" },
    { code: "no", name: "Norsk" },
    { code: "uz", name: "o'zbek kishisi" },
    { code: "pl", name: "Polskie" },
    { code: "pt", name: "Português" },
    { code: "ro", name: "Română" },
    { code: "sl", name: "Slovenščina" },
    { code: "sk", name: "Slovenčina" },
    { code: "fi", name: "Suomi" },
    { code: "sv", name: "Svensk" },
    { code: "tk", name: "Türkçe" },
    { code: "nd", name: "Zimbabwe-Ndebele" },
    { code: "sn", name: "Zimbabwe-Shona" },
    { code: "el", name: "Ελληνικά" },
    { code: "bg", name: "българин" },
    { code: "ky", name: "Кыргызча" },
    { code: "mk", name: "македонски" },
    { code: "ru", name: "Русский" },
    { code: "sr", name: "српски" },
    { code: "uk", name: "українець" },
    { code: "cnr", name: "црногорски" },
    { code: "he", name: "עִברִית " },
    { code: "ar", name: "العربية" },
    { code: "th", name: "คนไทย" },
    { code: "ko", name: "한국인" },
    { code: "ja", name: "日本語" },
  ],
  eua: [
    { code: "en", name: "English" },
    { code: "da", name: "Dansk" },
    { code: "el", name: "Ελληνικά" },
    { code: "es", name: "Español" },
    { code: "fr", name: "Français" },
    { code: "it", name: "Italiano" },
  ],
};

export const returnSupportedLanguages = (languagesFor?: string) => {
  if (!languagesFor) {
    return supportedLanguages["all"];
  } else if (languagesFor.toLowerCase() === "eua") {
    return supportedLanguages['eua'];
  }
};

export const isLanguageSupported = (
  langCode: string,
  translationGroup?: string
) => {
  const supportedLanguages = returnSupportedLanguages(translationGroup);
  if (supportedLanguages) {
    return supportedLanguages.find((sl) =>
      langCode.toLowerCase().includes(sl.code.toLowerCase())
    );
  } else {
    return false;
  }
};

export const setUpdatedLanguage = (
  updatedLanguage: string,
  writeToStorage?: boolean
) => {
  I18n.locale = updatedLanguage;

  if (writeToStorage) {
    const currentSettings = readTokenFromStorage();
    currentSettings.userLanguage = updatedLanguage;
    writeTokenToStorage(currentSettings);
  }
};

I18n.translations = {
  ar,
  bg,
  bs,
  cnr,
  cs,
  da,
  de,
  el,
  en,
  es,
  fi,
  fr,
  he,
  hr,
  hu,
  id,
  is,
  it,
  ja,
  ko,
  ky,
  mk,
  ml,
  mt,
  nd,
  nl,
  no,
  pl,
  pt,
  ro,
  ru,
  sk,
  sl,
  sn,
  sr,
  sv,
  th,
  tk,
  uk,
  uz,
};

export const decideLocale = () => {
  const currentSettings = readTokenFromStorage();
  const tlGroup =
    currentSettings && currentSettings.translationGroup
      ? currentSettings.translationGroup
      : "";
  const isNavigatorLangSupported = isLanguageSupported(
    navigator.language,
    tlGroup
  );

  if (currentSettings) {
    return currentSettings.userLanguage;
  } else {
    return isNavigatorLangSupported ? navigator.language : "en";
  }
};

const translateWithTranslationGroupSupport = (key: string) => {
  const translationGroup = readTokenFromStorage().translationGroup;
  let tlCheck;
  if (translationGroup) {
    tlCheck = I18n.translate(`${key}_${translationGroup.toLowerCase()}`);
    if (!tlCheck.includes("missing")) {
      return tlCheck;
    } else {
      return I18n.translate(key);
    }
  } else {
    return I18n.translate(key);
  }
};

I18n.fallbacks = true;

I18n.t = translateWithTranslationGroupSupport;

I18n.locale = decideLocale();

export default I18n;
