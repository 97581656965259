import React from "react";

// Interfaces
import DynamicRedButtonLogoInterface from "./DynamicRedButtonLogoInterface";

const DynamicRedButtonLogo: React.FC<DynamicRedButtonLogoInterface> = ({
  sportCode,
  className,
  lightLogo,
}) => {
  const logoMode = lightLogo ? "light" : "dark";

  return (
    <img
      src={`assets/logos/redbutton/${logoMode}/${sportCode}.png`}
      alt="Red Button"
      className={className}
      data-testid="test-logo-id"
    />
  );
};

export default DynamicRedButtonLogo;
