const ROUTES = {
  LOGIN: "/login",
  REPORT: "/submitreport",
  REPORT_CONFIRMATION: "/reportconfirmation",
  RED_BUTTON: "/redbutton",
  CODE_OF_CONDUCT: "/codeofconduct",
  SETTINGS: "/settings",
  INFO: "/info",
};

export default ROUTES;
