import axios from "../api/baseApi";
import { readTokenFromStorage } from "./TokenService";
import moment from "moment-timezone";

// Interfaces
import SubmitReportInterface from "../interfaces/report/SubmitReportInterface";
import ReportResponseInterface from "../interfaces/report/ReportResponseInterface";
import { AxiosResponse } from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Send report
export const sendReport = async (data: SubmitReportInterface) => {
  try {
    if (!data) {
      return;
    }

    const tokenData = readTokenFromStorage();
    const time = moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSZ");
    const tz = moment.tz.guess();

    const payLoad = {
      reportedAt: time,
      reportedAtTimeZone: tz,
      unionCode: tokenData.unionCode,
      report: {
        ...data,
      },
    };

    return await axios
      .post(`${BASE_URL}report`, payLoad)
      .then((result: AxiosResponse<ReportResponseInterface>) => {
        return (
          result.status === 200 ||
          result.status === 201 ||
          result.status === 204
        );
      })
      .catch((error: string) => {
        console.log(`Error: ${error}`);
      });
  } catch (error) {
    console.log(`Error: ${error}`);
  }
};

export const removeAttachmentFromReport = async (
  id: string,
  deleteToken: string
): Promise<boolean> => {
  try {
    if (!id || !deleteToken) {
      return false;
    }

    const result = await axios
      .delete(`${BASE_URL}attachment?attachmentId=${id}&deleteToken=${deleteToken}`)
      .then((result: AxiosResponse<any>) => {
        switch (result.status) {
          case 204:
            return true;
          default:
            return false;
        }
      })
      .catch((error) => {
        return false;
      });

    return result;
  } catch (error) {
    return false;
  }
};
