import axios  from "../api/baseApi";
import I18n from "../services/LanguageService";

// Interfaces
import LoginStateInterface from "../interfaces/login/LoginStateInterface";
import OldTokenInterface from "../interfaces/tokens/OldTokenInterface";

const localStorageTokenKey = "rb-loginTokenState";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Sends a request to see if the code can be used
export const validateToken = async (code: string) => {
  try {
    if (!code) {
      return;
    }

    return await axios
      .post(`${BASE_URL}token/pin?pin=${code}`)
      .then((result: any) => {
        if (result.status === 200 && result.data) {
          return result.data;
        } else {
          throw new Error(I18n.t("login_invalid"));
        }
      })
      .catch((error: Error) => {
        throw new Error(I18n.t("login_invalid"));
      });
  } catch (error) {
    throw new Error(I18n.t("login_invalid"));
  }
};

export const migrateToken = async (tokenData: OldTokenInterface) : Promise<LoginStateInterface | string> => {
  try {
    if (!tokenData.token || !tokenData.league) {
      return 'Missing information for migration.';
    }

    const migrateResult = await axios.get(`${BASE_URL}token/pin/validate?unionCode=${tokenData.league}&token=${tokenData.token}`);

    switch (migrateResult.status) {
      case 200:
        return migrateResult.data;
      default:
        return 'Something went wrong while migrating.';
    }
  } catch (error) {
    switch (error.response.status) {
      case 403:
        return "Migration is not allowed.";
      default:
        return "Something went wrong while trying to retrieve the users, please try again later.";
    }
  }
}

// Writes the loginState with token data to the localstorage
export const writeTokenToStorage = (tokenData: LoginStateInterface) => {
  localStorage.setItem(localStorageTokenKey, JSON.stringify(tokenData));
};

// Retrieves the loginState with token data from localStorage
export const readTokenFromStorage = () => {
  const localLoginTokenData = localStorage.getItem(localStorageTokenKey);

  return localLoginTokenData
    ? JSON.parse(localLoginTokenData)
    : { isLoggedIn: false };
};
