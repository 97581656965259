import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { useForm, Controller } from "react-hook-form";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButton,
  IonInput,
  IonTextarea,
  IonList,
  IonLabel,
  IonItem,
  IonToggle,
  IonListHeader,
  IonSelect,
  IonButtons,
  IonSelectOption,
  IonModal,
  IonSpinner,
} from "@ionic/react";
import moment from "moment-timezone";

// Services
import I18n from "../services/LanguageService";
import {
  sendReport,
  removeAttachmentFromReport,
} from "../services/ReportService";
import { readTokenFromStorage } from "../services/TokenService";
import CountryService from "../services/CountryService";
import { uploadFile } from "../services/UploadService";

// Constants
import ROUTES from "../constants/Routes";
import EUA_LEAGUES from "../constants/EuaLeagues";

// Interfaces
import SubmitReportInterface from "../interfaces/report/SubmitReportInterface";
import Country from "../interfaces/types/Country";
import LoginStateInterface from "../interfaces/login/LoginStateInterface";
import ReportPageInterface from "../interfaces/report/ReportPageInterface";
import Attachment from "../interfaces/attachments/Attachment";

// Components
import { showToast } from "../components/atom/Toast";
import DynamicRedButtonLogo from "../components/atom/top-bar/DynamicRedButtonLogo";

const countryService = new CountryService();
const Report: React.FC<ReportPageInterface> = ({ sportCode, unionCode }) => {
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [loginState, setLoginState] = useState<LoginStateInterface | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const { control, handleSubmit, formState, reset } = useForm<
    SubmitReportInterface
  >({
    defaultValues: {
      playerWasContacted: false,
      colleagueWasContacted: false,
      heardRumor: false,
      other: false,
      information: "",
      countryOfCompetition: "",
      contactName: "",
      contactDetails: "",
      attachmentIds: [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const history = useHistory();
  const hiddenUploadRef = useRef(null);
  const stateRef = useRef<Attachment[]>();
  stateRef.current = attachments;

  useEffect(() => {
    countryService.loadAvailableCountries().then((result: Country[]) => {
      if (!loginState) {
        setLoginState(readTokenFromStorage);
      } else {
        reset({
          playerWasContacted: false,
          colleagueWasContacted: false,
          heardRumor: false,
          other: false,
          information: "",
          countryOfCompetition: loginState.countryIsoCode,
          contactName: "",
          contactDetails: "",
        });
      }
      setCountries(result);
      setIsLoading(false);
    });
  }, [reset, loginState]);

  const handleFileButtonClick = (event: any) => {
    if (hiddenUploadRef && hiddenUploadRef.current) {
      // @ts-ignore
      hiddenUploadRef.current!.click();
    }
  };

  const removeAttachment = async (id: string, deleteToken: string) => {
    await removeAttachmentFromReport(id, deleteToken);

    setAttachments(attachments.filter((a) => a.id !== id));
  };

  const setProgressForAttachment = (
    fileName: string,
    progressValue: number,
    id?: string,
    deleteToken?: string
  ) => {
    const currentAttachments = stateRef.current;
    if (currentAttachments) {
      const indexOfAttachment = currentAttachments.findIndex(
        (f: Attachment) => f.name === fileName
      );
      if (indexOfAttachment !== -1) {
        currentAttachments[indexOfAttachment].progression = progressValue;
        currentAttachments[indexOfAttachment].deleteToken = deleteToken
          ? deleteToken
          : undefined;
        currentAttachments[indexOfAttachment].id = id ? id : undefined;
        setAttachments([...currentAttachments]);
      }
    }
  };

  const fileForUpload = async (files: FileList | null, event: any) => {
    const time = moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSZ");
    const tz = moment.tz.guess();

    if (files && files.length > 0) {
      const filesToUpload = Array.from(files);

      filesToUpload.forEach((file: File) => {
        const doesFileExist = attachments.filter(a => a.name === file.name);

        if (doesFileExist.length > 0) {
          return;
        }

        uploadFile({
          data: {
            unionCode: loginState!.unionCode!,
            uploadedAt: time,
            uploadedAtTimeZone: tz,
            file,
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {},
          onUploadComplete: (result: Attachment) => {
            setProgressForAttachment(
              result.name,
              result.progression,
              result.id,
              result.deleteToken
            );
          },
        }).catch(() => {
          showToast(I18n.t("error_try_again"), "long");
          setAttachments((prevState: Attachment[]) => [
            ...prevState.filter((a) => a.name !== file.name),
          ]);
        });

        setAttachments((prevState: Attachment[]) => [
          ...prevState,
          ...[
            {
              deleteToken: undefined,
              id: undefined,
              name: file.name,
              progression: 0,
            },
          ],
        ]);
      });
    } else {
      return;
    }

    event.target.value = null;
  };

  const UnionLogoHeader = () => {
    const backgroundClass = loginState!.backgroundColor
      ? "c-logo--background"
      : "";
    const backgroundStyle = loginState!.backgroundColor
      ? { backgroundColor: loginState!.backgroundColor }
      : { backgroundColor: "transparent" };

    return (
      <div
        className={`c-logo c-logo--association ${backgroundClass}`}
        style={backgroundStyle}
      >
        <img src={loginState!.logoUrl} alt={loginState!.unionCode} />
      </div>
    );
  };

  const canSubmit = () => {
    return attachments.filter((f: Attachment) => f.progression < 100);
  };

  const getFileIds = () => {
    const fileIds: string[] = [];

    attachments.forEach((file: Attachment) => {
      if (file.id) {
        fileIds.push(file.id);
      }
    });

    return fileIds;
  };

  const onSubmit = async (data: SubmitReportInterface) => {
    if (isSubmitting) {
      return;
    }

    const filesInUpload: Attachment[] = canSubmit();
    setIsSubmitting(true);
    if (formState.isValid && filesInUpload.length === 0) {
      data.attachmentIds = getFileIds();
      await sendReport(data).then((result) => {
        if (result) {
          history.push(ROUTES.REPORT_CONFIRMATION);
        } else {
          setIsSubmitting(false);
          setIsSubmitModalOpen(false);
          showToast(I18n.t("error_try_again"), "long");
        }
      });
    } else {
      setIsSubmitting(false);
    }
  };

  let countryOptions = () => {
    return Object.values(countries).map((language: any) => (
      <IonSelectOption
        data-test-id="country-option"
        className="c-select__option"
        value={language["code"]}
        key={language["code"]}
      >
        {language["name"]}
      </IonSelectOption>
    ));
  };

  let contactWasContactedLabel = () => {
    if (unionCode === EUA_LEAGUES.AJFSF) {
      return I18n.t("report_form_contactmate_f")
        ? I18n.t("report_form_contactmate_f")
        : I18n.t("report_form_contactmate");
    } else {
      return I18n.t("report_form_contactmate");
    }
  };

  let currentUploadItems = () => {
    return stateRef.current!.map((attachment: Attachment) => {
      return (
        <IonItem
          className="c-ion-item--line"
          key={`attachment-${attachment.name}`}
        >
          <span className="c-report__attachment-name">{attachment.name}</span>
          {attachment.progression < 100 && (
            <IonSpinner className="no-margin" slot="end" />
          )}
          {attachment.progression === 100 && (
            <button
              slot="end"
              type="button"
              className="btn btn--hollow c-ion-item--button"
              onClick={() =>
                removeAttachment(attachment.id!, attachment.deleteToken!)
              }
            >
              <span className="icon-trash"></span>
            </button>
          )}
        </IonItem>
      );
    });
  };

  return (
    <IonPage>
      <IonHeader className="c-header">
        <IonToolbar className="c-toolbar">
          <IonItem slot="start" className="c-redbutton-logo" lines="none">
            <DynamicRedButtonLogo sportCode={sportCode} />
          </IonItem>
          <span className="c-header__title">{I18n.t("tab_report")}</span>
          {!isLoading && (
            <IonButtons className="c-header__cta" slot="end">
              <IonButton
                onClick={() => setIsSubmitModalOpen(true)}
                disabled={!formState.isValid || isSubmitting}
              >
                {I18n.t("report_form_send")}
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      {!isLoading && loginState && (
        <IonContent className="ion-content">
          <IonItem className="c-banner c-banner--report">
            <div className="c-banner__content">
              <UnionLogoHeader />
            </div>
          </IonItem>
          <form className="c-form" onSubmit={() => setIsSubmitModalOpen(true)}>
            <div className="c-container-toggle-list">
              <IonList className="c-toggle-list">
                <IonItem className="c-ion-item">
                  <IonListHeader className="c-ion-list-header">
                    {I18n.t("your_report")}
                  </IonListHeader>
                </IonItem>
                <IonItem className="c-ion-item">
                  <IonLabel className="c-ion-label">
                    {I18n.t("report_form_contactme")}
                  </IonLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <IonToggle
                        data-test-id="player-contacted-toggle"
                        mode="ios"
                        color="secondary"
                        onIonChange={(e) => onChange(e.detail.checked)}
                        checked={value}
                      />
                    )}
                    name="playerWasContacted"
                    control={control}
                  />
                </IonItem>
                <IonItem className="c-ion-item">
                  <IonLabel className="c-ion-label">
                    {contactWasContactedLabel()}
                  </IonLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <IonToggle
                        mode="ios"
                        color="secondary"
                        onIonChange={(e) => onChange(e.detail.checked)}
                        checked={value}
                      />
                    )}
                    name="colleagueWasContacted"
                    control={control}
                  />
                </IonItem>
                <IonItem className="c-ion-item">
                  <IonLabel className="c-ion-label">
                    {I18n.t("report_form_rumor")}
                  </IonLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <IonToggle
                        mode="ios"
                        color="secondary"
                        onIonChange={(e) => onChange(e.detail.checked)}
                        checked={value}
                      />
                    )}
                    name="heardRumor"
                    control={control}
                  />
                </IonItem>
                <IonItem className="c-ion-item">
                  <IonLabel className="c-ion-label">
                    {I18n.t("report_form_other")}
                  </IonLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <IonToggle
                        mode="ios"
                        color="secondary"
                        onIonChange={(e) => onChange(e.detail.checked)}
                        checked={value}
                      />
                    )}
                    name="other"
                    control={control}
                  />
                </IonItem>
              </IonList>
            </div>

            <IonList className="c-form__list c-form__list--first">
              <IonItem className="c-ion-item">
                <IonListHeader className="c-ion-list-header">
                  {I18n.t("report_form_comment")}
                </IonListHeader>
              </IonItem>
              <IonItem className="c-ion-item">
                <Controller
                  render={({ onChange, value }) => (
                    <IonTextarea
                      data-test-id="report-information"
                      className="o-textarea"
                      placeholder={I18n.t("report_form_comment_placeholder")}
                      rows={3}
                      onIonChange={(e) => onChange(e.detail.value)}
                      defaultValue={value}
                    />
                  )}
                  rules={{ required: true }}
                  name="information"
                  control={control}
                />
              </IonItem>
            </IonList>

            <IonList className="c-form__list">
              <IonItem className="c-ion-item">
                <IonListHeader className="c-ion-list-header">
                  {I18n.t("country_of_competition")}
                </IonListHeader>
              </IonItem>
              <IonItem className="c-ion-item">
                <IonLabel className="o-label">
                  <span className="icon-dropdown"></span>
                  <Controller
                    render={({ onChange, value, name }) => (
                      <IonSelect
                        data-test-id="country-select"
                        className="o-select"
                        interface="popover"
                        placeholder={I18n.t("select_country_of_competition")}
                        onIonChange={(e) => onChange(e.detail.value)}
                        value={value}
                      >
                        {countryOptions()}
                      </IonSelect>
                    )}
                    defaultValue={loginState.countryIsoCode}
                    rules={{ required: false }}
                    name="countryOfCompetition"
                    control={control}
                  />
                </IonLabel>
              </IonItem>
            </IonList>

            <IonList className="c-form__list">
              <IonItem className="c-ion-item">
                <IonListHeader className="c-ion-list-header">
                  {I18n.t("report_form_contactinfo")}
                </IonListHeader>
                <span slot="end">{I18n.t("optional")}</span>
              </IonItem>
              <IonItem className="c-ion-item">
                <Controller
                  render={({ onChange, value }) => (
                    <IonInput
                      className="input input--primary"
                      data-test-id="contact-name-field"
                      placeholder={I18n.t("report_form_name")}
                      onIonChange={(e) => onChange(e.detail.value)}
                      defaultValue={value}
                    />
                  )}
                  rules={{ required: false }}
                  name="contactName"
                  control={control}
                />
              </IonItem>
              <IonItem className="c-ion-item">
                <Controller
                  render={({ onChange, value }) => (
                    <IonInput
                      className="input input--primary"
                      placeholder={I18n.t(
                        "report_form_contactinfo_placeholder"
                      )}
                      onIonChange={(e) => onChange(e.detail.value)}
                      defaultValue={value}
                    />
                  )}
                  rules={{ required: false }}
                  name="contactDetails"
                  control={control}
                />
              </IonItem>
            </IonList>

            {attachments.length > 0 && (
              <IonList className="c-form__list">
                <IonItem className="c-ion-item">
                  <IonListHeader className="c-ion-list-header">
                    {I18n.t("attachments")}
                  </IonListHeader>
                </IonItem>
                {currentUploadItems()}
              </IonList>
            )}

            <IonItem className="c-ion-item">
              <button
                className="btn btn--upload"
                type="button"
                onClick={handleFileButtonClick}
              >
                {I18n.t("add_files")} <span className="icon-plus"></span>
              </button>
              <input
                type="file"
                onChange={(event) => fileForUpload(event.target.files, event)}
                ref={hiddenUploadRef}
                multiple
                style={{ display: "none" }}
              />
            </IonItem>

            <IonButton
              className="btn btn--solid"
              color="tertiary"
              style={{ marginTop: "16px" }}
              data-test-id="report-submit-button"
              onClick={() => setIsSubmitModalOpen(true)}
              disabled={!formState.isValid || isSubmitting}
            >
              <span>{I18n.t("report_form_send")}</span>
              <span className="icon-next"></span>
            </IonButton>
          </form>
        </IonContent>
      )}

      <IonModal
        isOpen={isSubmitModalOpen}
        onDidDismiss={() => setIsSubmitModalOpen(false)}
        swipeToClose={true}
        backdropDismiss={true}
        keyboardClose={true}
        cssClass="c.modal"
      >
        <div className="c-modal__body">
          <h1>
            {I18n.t("report_form_send_title")} {isSubmitting && <IonSpinner />}
          </h1>
          <p>{I18n.t("report_form_send_description")}</p>
        </div>
        <div className="c-modal__footer">
          <button
            className="btn btn--hollow"
            onClick={() => setIsSubmitModalOpen(false)}
          >
            {I18n.t("popup_cancel")}
          </button>
          <IonButton
            className="btn btn--solid"
            onClick={handleSubmit(onSubmit)}
          >
            {I18n.t("report_form_send")}
          </IonButton>
        </div>
      </IonModal>
    </IonPage>
  );
};

export default Report;
