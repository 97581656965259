import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonItem,
  IonPage,
  IonText,
  IonToolbar,
} from "@ionic/react";
import I18n from "../services/LanguageService";

// Components
import DynamicRedButtonLogo from "../components/atom/top-bar/DynamicRedButtonLogo";

// Interfaces
import ReportConfirmationPageInterface from "../interfaces/report/ReportConfirmationPageInterface";

const ReportConfirmation: React.FC<ReportConfirmationPageInterface> = ({ sportCode }) => {
  return (
    <IonPage>
      <IonHeader className="c-header">
        <IonToolbar className="c-toolbar">
          <IonItem slot="start" className="c-redbutton-logo" lines="none">
            <DynamicRedButtonLogo sportCode={sportCode} />
          </IonItem>
          <h1 className="c-header__title">
            {I18n.t("report_form_title")}
          </h1>
          <IonButtons className="c-header__cta" slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-content ion-content--has-bg">
        <div className="ion-content__bg-image">
          <IonText className="c-text">
            <h2>{I18n.t("report_completed_title")}</h2>
            <p>{I18n.t("report_completed_description")}</p>
          </IonText>

          <div className="c-report-confirmation">
            <div className="c-report-confirmation__inner">
              <IonList>
                <IonItem className="c-ion-item">
                  <a className="c-link" href="mailto:redbutton@fifpro.org">
                    <span className="icon-mail"></span>
                    <span>redbutton@fifpro.org</span>
                  </a>
                </IonItem>
              </IonList>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ReportConfirmation;
