import React from "react";
import {IonItem, IonLabel, IonList} from "@ionic/react";
import I18n from "../../../services/LanguageService";

const RedButtonContent: React.FC = () => {
    return (
        <IonList lines="none" className="c-list c-list--numbered">
            <IonItem>
                <IonLabel className="c-list__label">
                    <span className="c-list__number">{I18n.t("code_of_conduct_bullet_1")}</span>
                    <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_1")}
              </span>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel className="c-list__label">
                    <span className="c-list__number">{I18n.t("code_of_conduct_bullet_2")}</span>
                    <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_2")}
              </span>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel className="c-list__label">
                    <span className="c-list__number">{I18n.t("code_of_conduct_bullet_3")}</span>
                    <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_3")}
              </span>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel className="c-list__label">
                    <span className="c-list__number">{I18n.t("code_of_conduct_bullet_4")}</span>
                    <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_4")}
              </span>
                </IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel className="c-list__label">
                    <span className="c-list__number">{I18n.t("code_of_conduct_bullet_5")}</span>
                    <span className="c-list__label-txt">
                {I18n.t("code_of_conduct_topic_5")}
              </span>
                </IonLabel>
            </IonItem>
        </IonList>
    )
}

export default RedButtonContent;