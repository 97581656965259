import { Redirect, Route } from "react-router-dom";
import React from "react";

// Interfaces
import ProtectedRouteInterface from "../../interfaces/components/ProtectedRouteInterface";

// TODO: document it?
const ProtectedRoute: React.FC<ProtectedRouteInterface> = ({
  isLoggedIn,
  path,
    render,
}) => {
  return isLoggedIn ? (
    <Route path={path} exact>
      {render()}
    </Route>
  ) : (
    <Redirect push to="/login" />
  );
};

export default ProtectedRoute;
