import React from "react";
import { IonText } from "@ionic/react";
import I18n from "../../../services/LanguageService";

const EuaContent: React.FC = () => {
  return (
    <div>
      <IonText className="c-text">
        <h2>{I18n.t("code_of_conduct_bullet_1")}</h2>
        <p>{I18n.t("code_of_conduct_topic_1")}</p>
      </IonText>

      <IonText className="c-text">
        <h2>{I18n.t("code_of_conduct_bullet_2")}</h2>
        <p>{I18n.t("code_of_conduct_topic_2")}</p>
      </IonText>

      <IonText className="c-text">
        <h2>{I18n.t("code_of_conduct_bullet_3")}</h2>
        <p>{I18n.t("code_of_conduct_topic_3")}</p>
      </IonText>

      <IonText className="c-text">
        <h2>{I18n.t("code_of_conduct_bullet_4")}</h2>
        <p>{I18n.t("code_of_conduct_topic_4")}</p>
      </IonText>

      <IonText className="c-text">
        <h2>{I18n.t("code_of_conduct_bullet_5")}</h2>
        <p>{I18n.t("code_of_conduct_topic_5")}</p>
      </IonText>
    </div>
  );
};

export default EuaContent;
